//
//
//
//
//
//

export default {
  name: 'FrontendLayout',
  head() {
    const head = {
      ...this.$nuxtI18nHead({
        addSeoAttributes: true,
      }),
      script: [
        {
          type: 'text/javascript',
          src: this.$config.squareurl,
        },
        {
          defer: true,
          type: 'text/javascript',
          src: this.$config.paypalUrl,
        },
      ],
    }
    return head
  },
}
